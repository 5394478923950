/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../style/abstract/theme_colors';
@import '../../style/abstract/theme_sizes';

@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme_mixin';

.Footer {
    $block: &;

    .isCartPage &,
    .isCheckoutPage & {
        display: none;
    }

    &-Content {
        background-color: var(--theme-color-10);
    }

    &-Columns {
        max-width: var(--content-wrapper-width);
        padding: var(--theme-spacing-96) var(--theme-spacing-24);

        @include before-desktop {
            padding: var(--theme-spacing-64) var(--theme-spacing-24) var(--theme-spacing-16);
            text-align: left;
        }
    }

    &-Column {
        color: var(--white);
        max-width: none;
        padding-right: var(--theme-spacing-32);
        width: calc(100% / 4);
        max-width: unset;

        @include before-desktop {
            padding-right: 0;
            width: 100%;
            margin-bottom: var(--theme-spacing-48);
            display: flex;
            flex-wrap: wrap;
        }

        &:not(:last-of-type) {
            @include desktop {
                max-width: unset;
                padding-right: var(--theme-spacing-32);
            }
            @include before-desktop {
                margin-bottom: var(--theme-spacing-48);
            }
        }

        &:last-child {
            padding-right: 0;
        }
        
        &_type_contactUs {
            @include before-desktop {
                order: 3;
            }

            &:not(:last-of-type) {
                @include before-desktop {
                    margin-bottom: 0;
                }
            }

            & #{$block}-ColumnItem {
                margin-bottom: var(--theme-spacing-8) !important;
                
                @include before-desktop {
                    width: 100%;
                }

                &:nth-of-type(odd) {
                    padding-right: unset;
                }
            }
        }

        &_isNewsletter {
            @include before-desktop {
                order: -1;
                justify-content: center;
            }

            & .Footer-ColumnTitle {
                @include before-desktop {
                    text-align: center;
                    font-size: 3.0rem;
                    padding: 0 var(--theme-spacing-16);
                }
            }

            & .Footer-ColumnContent {
                @include before-desktop {
                    width: 100%;
                }
            }
        }

        &_type_logo {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include before-desktop {
                align-items: center;
            }
        }
    }

    &-ColumnItem {
        @include font-footer;

        margin-bottom: var(--theme-spacing-24);

        @include before-desktop {
            width: 50%;
            //font-size: var(--theme-font-size-footer-p-mobile);
            margin-bottom: var(--theme-spacing-16);
        }

        &:nth-of-type(odd) {
            @include before-desktop {
                padding-right: var(--theme-spacing-24);
            }
        }

        &:nth-last-of-type(2) {
            @include before-desktop {
                margin: 0;
            }
        }

        &_type_cvrNummer {
            @include before-desktop {
                text-align: center;
            }
        }

        & > a {
            color: var(--white);
        }
    }

    &-ColumnTitle {
        @include font-footer-heading;
        margin: 0 0 var(--theme-spacing-48);
        text-transform: none;
        color: var(--primary-base-color);

        @include before-desktop {
            margin: 0 0 var(--theme-spacing-24);
            width: 100%;
        }
    }

    &-CopyrightContentWrapper {
        background-color: var(--theme-color-10);
    }

    &-CopyrightContentWrapperInner {
        border-top: 1px solid var(--theme-color-10a);
        padding: var(--theme-spacing-64) 0;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        max-width: var(--content-wrapper-width);
        width: 100%;
        align-items: center;

        @include before-desktop {
            grid-template-columns: auto;
            padding: var(--theme-spacing-24) 0;
        }
    }

    &-CopyrightContent {
        padding: 0 var(--theme-spacing-24);

        & .CmsBlock-Wrapper_type_copyright {
            justify-self: flex-start;

            @include before-desktop {
                order: 1;
                padding-top: var(--theme-spacing-16);
                justify-self: center;
            }
        }

        & .CmsBlock-Wrapper_type_icons {
            justify-self: center;
        }

        & .CmsBlock-Wrapper_type_certificates {
            justify-self: flex-end;

            @include before-desktop {
                padding-top: var(--theme-spacing-16);
                justify-self: center;
            }
        }
    }

    &-CmsBlockWrapper {
        & .Footer-Column_isNewsletter {
            padding-left: 0;

            @include before-desktop {
                width: 100%;
            }
        }

        & .CmsBlock-Wrapper_type_cmsBlock {
            @include before-desktop {
                width: 100%;
            }
        }
    }

    &-CmsBlock {
        // display: grid;
        // grid-auto-flow: column;
        // grid-auto-columns: minmax(0, 1fr);
        display: flex;

        @include before-desktop {
            flex-direction: column;
            width: 100%;
        }
    
        & .Footer-Column {
            width: calc(100% / 3);

            @include before-desktop {
                width: 100%;
            }
        }
    }

    &-Emaerket {
        max-width: var(--theme-spacing-72);
        margin-top: var(--theme-spacing-24);
        
        @include before-desktop {
            position: absolute;
            width: 100%;
            right: 0;
            bottom: 40px;
        }
    }

    &-Logo {
        padding-bottom: var(--theme-spacing-32);
    }

    &-SocialMedia {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-end;
    }
}