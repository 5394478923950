/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../style/abstract/theme_colors';
@import '../../style/abstract/theme_sizes';

@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme_mixin';

.ProductCard {
    $block: &;
    
    margin-bottom: 0;
    background-color: var(--theme-color-3);
    transition: background-color .25s ease;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        @include desktop {
            background-color: var(--theme-color-1);
            transition: background-color .25s ease;
        }

        & .Image-Content {
            color: var(--white);
        }
    }

    & > #{$block}-Link {
        padding: var(--theme-spacing-20);
    }

    &-FigureReview {
        position: relative;

        @include desktop {
            padding: var(--theme-spacing-48) 0;
        }
    }

    &-Figure {
        @include desktop {
            transition: all .25s ease;
            transform: scale(0.95);
        }

        #{$block}:hover & {
            @include desktop {
                transform: scale(1.0);
            }
        }

        // Color multiplication for white background images turns red, so it will not work with non-white backgrounds.
        // & .Image {
        //     background-color: var(--theme-color-3);
        //     transition: all .25s ease;

        //     #{$block}:hover & {
        //         @include desktop {
        //             background-color: var(--primary-base-color);
        //         }
        //     }

        //     & .Image-Image {
        //         mix-blend-mode: multiply;
        //     }
        // }
    }

    @keyframes africaImage {
        0% { transform: scale(0); }
        40% { transform: scale(1.05); }
        70% { transform: scale(0.9); }
        100% { transform: scale(0.95); }
    }

    &-AfricaImage {
        background-image: url(./images/africa.svg);
        background-repeat: no-repeat;
        background-position: center;
        filter: var(--theme-color-1c-filter);
        width: 100%;
        height: 100%;
        position: absolute;
        transform: scale(0);

        #{$block}:hover & {
            animation-name: africaImage;
            animation-duration: .5s;
            animation-timing-function: ease;
            transform: scale(0.95);
        }
    }

    &-ProductActions {
        top: var(--theme-spacing-8);
        right: var(--theme-spacing-8);
    }

    &-Badges {
        position: absolute;
        top: 0;
        left: calc(var(--theme-spacing-20) * -1);
    }

    &-Badge {
        padding: var(--theme-spacing-8) var(--theme-spacing-16);
        border-top-right-radius: 999px;
        border-bottom-right-radius: 999px;
        margin-bottom: var(--theme-spacing-8);

        @include font-product2;

        @include before-desktop {
            font-size: 10px;
            letter-spacing: 0.05rem;
        }
        
        color: var(--white);
        background-color: var(--theme-color-1);

        &.SpecialPrice {
            background-color: var(--theme-color-badge-1);
        }

        &_type_badge2 {
            background-color: var(--theme-color-badge-2);
        }

        &.BadgeRoastProfile {
            background-color: var(--theme-color-badge-3);
        }

        &_type_badge4 {
            background-color: var(--theme-color-badge-4);
            color: var(--black);
        }
        &.NoStock {
            background-color: var(--theme-color-10c);
            color: var(--black);
        }
        &.BadgeNewProduct {
            background-color: #e0d8c3;
            color: var(--black);
        }
        &.RestSalg {
            background-color: #f3d46a;
            color: var(--black);
        }
    }

    &-Content {
        padding: 0;
        text-align: center;

        #{$block}:hover & {
            color: var(--white);
        }
    }

    &-Name {
        margin: 0 0 var(--theme-spacing-8);
        white-space: normal;

        @include font-product1;

        #{$block}:hover & {
            @include desktop {
                color: var(--white);
            }
        }
    }

    &-ProcessingType {
        font-size: 1.6rem;
        margin: 0 0 var(--theme-spacing-8);     


        #{$block}:hover & {
            @include desktop {
                //color: var(--theme-color-10e);
            }
        }
    }

    &-BeanType {
        font-size: 1.6rem;
        margin: 0 0 var(--theme-spacing-8);     
        color: var(--theme-color-10b);

        #{$block}:hover & {
            @include desktop {
                color: var(--theme-color-10e);
            }
        }
    }

    &-BrewingType {
        margin: 0 0 var(--theme-spacing-8);
        text-transform: uppercase;
        color: var(--primary-base-color);

        #{$block}:hover & {
            @include desktop {
                color: var(--theme-color-10e);
            }
        }
    }

    &-PriceBadge {
        display: none;
    }

    &-Price {
        @include font-product-price;

        #{$block}:hover & {
            @include desktop {
                color: var(--white);
            }
        }

        &_isConfig {
            display: inline;
        }
    }

    &-FromPrice {
        display: inline;
        margin-right: 5px;
    }

    &-ConfigurableOptions {
        min-height: auto;

        #{$block}:hover & {
            @include desktop {
                color: var(--white);
            }
        }
    }
}