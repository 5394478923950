/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// Font family
$theme-font-family-primary: 'Proxima Nova', sans-serif;
$theme-font-family-secondary: 'Playfair Display', serif;

// Scandi PWA default variables
$white: $theme-color-11;
$black: $theme-color-10;
$default-primary-light-color: $theme-color-1a;
$default-primary-base-color: $theme-color-1;
$default-primary-dark-color: $theme-color-1b;
$default-secondary-light-color: $theme-color-10e;
$default-secondary-base-color: $theme-color-3;
$default-secondary-dark-color: $theme-color-10d;
$font-muli: $theme-font-family-primary;
$font-standard-size: 62.5%;

// Custom variables
// $font-heavitas: 'Heavitas';
// $font-heavitas-regular: 'Heavitas-regular';
$font-weight: 400;
$basic-page-width: 1200px;

$ClerkBg: red;

// $font-color-brown: #624627;
// $price-color-brown: #3C2415;
// $filter-font-color: #3C2415;

// $btn-color-brown: #624627;
// $btn-color-white: white;
// $footer-bg: #3c2415;

// $instock-color: #82A77B;
// $outstock-color: #D6A59E;