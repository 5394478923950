.slick {
    $block: &;

    &-list {
        z-index: 1;
        
        @include desktop {
            pointer-events: none;
        }
    }

    &-arrow {
        display: none;

        @include desktop {
            display: block;
            width: 50%;
            height: 100%;
        }

        &::before {
            content: '';
            font-size: 0;
        }

        &#{$block}-prev {
            left: 0;
            transform: rotateZ(180deg);
            top: 0;

            @include desktop {
                cursor:url('icons/arrow-left-cursor.png') 0 32, pointer;
            }
        }

        &#{$block}-next {
            right: 0;
            transform: translateX(0);
            top: 0;

            @include desktop {
                cursor:url('icons/arrow-right-cursor.png') 64 32, pointer;
            }
        }
    }

    &-dots {
        margin-top: 0 !important;
        bottom: 10%;
        line-height: 0;
        font-size: 0;
        z-index: 2;

        & li {
            margin: 0;
            width: unset;
            height: unset;

            &:first-child {
                & button {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }
            }

            &:last-child {
                margin-bottom: 0;

                & button {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
            }

            &#{$block}-active {
                & button {
                    background-color: var(--theme-color-1);
                }
            }

            &::before {
                content: '';
            }

            & button {
                background-color: var(--white);
                height: 6px;
                width: 48px;
                padding: 0;

                @include phone {
                    width: 32px;
                }

                &::before {
                    content: '';
                    width: 0;
                    height: 0;
                }
            }
        }
    }

    &-slider {
        .ProductCard {
            &-Figure {
                & .Image:not(.Logo) {
                    width: 100%;
                    height: 100%;
                    padding-bottom: 100%;
        
                    & .Image-Image {
                        width: 100% !important;
                        height: 100% !important;
                        position: absolute;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}

.SliderWidget{
    &-Figure {
        & .Image:not(.Logo) {
            width: 100%;
            height: 100%;

            & .Image-Image {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

    &-Figcaption a {
        @include desktop {
            pointer-events: all;
        }
    }
}

.cms-slider {
    display: grid;
    justify-items: center;

    &.cols-1 { grid-template-columns: 1fr; }
    &.cols-2 { grid-template-columns: repeat(2, 1fr); }

    &.reverse { direction: rtl; }

    &-column {
        display: grid;
        justify-items: center;
        text-align: center;
        max-width: 48rem;
    }

    & h1 {
        @include font-heading1;
        margin: 0;

        @include phone {
            font-size: 2.2rem;
            line-height: 2.5rem;
        }
    }

    & p {
        @include font-paragraph;
        text-transform: none;
        margin: 0;
    }
}
