:root {
    // Content Width
    --cms-content-padding: var(--theme-spacing-24);
    --cms-width: calc(114rem - (var(--cms-content-padding) * 2));
    --cms-max-width: calc(100% - (var(--cms-content-padding) * 2));
    --cms-full-width: 100%;
    --cms-full-max-width: 100%;
    --cms-col-max-width: 80rem;
    --cms-col-max-width-small: 66rem;
    --cms-col-max-width-smaller: 48rem;
    --cms-content-width: 100%;
    --cms-content-max-width: var(--content-wrapper-width);

    // Content Height
    --height-100vh: calc(100vh - var(--header-nav-height));

    // Spacing
    --cms-spacing-x1: 1.2rem;
    --cms-spacing-x2: 2.4rem;
    --cms-spacing-x3: 4.8rem;

    // Spacing
    --cms-vertical-padding-x1: 2.4rem;
    --cms-vertical-padding-x2: 4.8rem;
    --cms-vertical-padding-x3: 7.2rem;

    // Spacing
    --cms-p-spacing-x1: 0.8rem;
    --cms-p-spacing-x2: 1.2rem;
    --cms-p-spacing-x3: 1.6rem;

    // Box Shadow
    --cms-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .3);

    // Colors
    --cms-grey-background: var(--grey-background-main);
    --cms-white-background: var(--white);
    --cms-theme-color-1-background: var(--primary-base-color);
    --cms-light-font-color: var(--white);

    // Button - General
    --cms-button-border-radius: var(--button-radius);
    --cms-button-font-weight: var(--button-font-weight);
    --cms-button-font-size: var(--button-font-size);
    --cms-button-font-size-mobile: 1.54rem;
    --cms-button-line-height: normal;
    --cms-button-padding: var(--button-padding);
    --cms-button-border-width: var(--button-border-width);
    --cms-button-border-style: solid;
    --cms-button-spacing: var(--cms-spacing-x2);

    // Button - Primary
    --cms-button-primary-color: var(--button-color);
    --cms-button-primary-background-color: var(--button-background);
    --cms-button-primary-border-color: var(--button-border);

    // Button - Primary Hover
    --cms-button-primary-hover-color: var(--button-hover-color);
    --cms-button-primary-hover-background-color: var(--button-hover-background);
    --cms-button-primary-hover-border-color: var(--button-hover-border);

    // Button - Primary Hollow
    --cms-button-primary-hollow-color: var(--button-background);
    --cms-button-primary-hollow-background-color: transparent;
    --cms-button-primary-hollow-border-color: var(--button-background);

    // Button - Primary Hollow Hover
    --cms-button-primary-hollow-hover-color: var(--button-color);
    --cms-button-primary-hollow-hover-background-color: var(--button-background);
    --cms-button-primary-hollow-hover-border-color: var(--button-background);

    // Button - Secondary
    --cms-button-secondary-color: var(--primary-base-color);
    --cms-button-secondary-background-color: var(--white);
    --cms-button-secondary-border-color: var(--primary-base-color);

    // Button - Secondary Hover
    --cms-button-secondary-hover-color: var(--primary-dark-color);
    --cms-button-secondary-hover-background-color: var(--secondary-light-color);
    --cms-button-secondary-hover-border-color: var(--primary-dark-color);

    // Button - Light Hollow
    --cms-button-light-hollow-color: var(--white);
    --cms-button-light-hollow-background-color: transparent;
    --cms-button-light-hollow-border-color: var(--white);

    // Button - Light Hollow Hover
    --cms-button-light-hollow-hover-color: var(--primary-base-color);
    --cms-button-light-hollow-hover-background-color: var(--white);
    --cms-button-light-hollow-hover-border-color: var(--primary-base-color);

    // Button - White
    --cms-button-white-color: var(--black);
    --cms-button-white-background-color: var(--white);
    --cms-button-white-border-color: var(--white);

    // Button - White Hover
    --cms-button-white-hover-color: var(--white);
    --cms-button-white-hover-background-color: transparent;
    --cms-button-white-hover-border-color: var(--white);

    // Button - White Hollow
    --cms-button-white-hollow-color: var(--white);
    --cms-button-white-hollow-background-color: transparent;
    --cms-button-white-hollow-border-color: var(--white);

    // Button - White Hollow Hover
    --cms-button-white-hollow-hover-color: var(--black);
    --cms-button-white-hollow-hover-background-color: var(--white);
    --cms-button-white-hollow-hover-border-color: var(--white);

    // Button - Black
    --cms-button-black-color: var(--white);
    --cms-button-black-background-color: var(--black);
    --cms-button-black-border-color: var(--black);

    // Button - Black Hover
    --cms-button-black-hover-color: var(--black);
    --cms-button-black-hover-background-color: transparent;
    --cms-button-black-hover-border-color: var(--black);

    // Button - Black Hollow
    --cms-button-black-hollow-color: var(--black);
    --cms-button-black-hollow-background-color: transparent;
    --cms-button-black-hollow-border-color: var(--black);

    // Button - Black Hollow Hover
    --cms-button-black-hollow-hover-color: var(--white);
    --cms-button-black-hollow-hover-background-color: var(--black);
    --cms-button-black-hollow-hover-border-color: var(--black);
}
