.cms-col {
    & p {
        font-family: var(--theme-font-family-primary);
        font-weight: 500;
        
        font-size: 1.9rem;
        line-height: 1.6;
        margin-bottom: 2.4rem;
    }

    & h1, & h2, & h3, & h4, & h5, & h6 {
        margin-top: 0;
    }

    & h1 {
        @include font-heading1;
        
        &.cms-h1-large {
            @include font-heading-extralarge
        }

        &.cms-font-primary {
            font-family: var(--theme-font-family-primary);
        }
    }
    
    & h2 {
        @include font-heading2;
    }

    & h3 {
        @include font-heading3;
    }

    & h4 {
        @include font-heading4;
    }

    & h5 {
        @include font-heading5;
    }

    & h6 {
        @include font-heading6;
    }
}

.cms-text-align {
    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }
}

.cms-text-shadow {
    &-dark {
        text-shadow: var(--theme-shadow-dark);
    }
    &-light {
        text-shadow: var(--theme-shadow-light);
    }
}

.cms-font {
    &-weight {
        &-normal {
            font-weight: var(--theme-font-weight-normal);
        }
        &-medium {
            font-weight: var(--theme-font-weight-medium);
        }
        &-semibold {
            font-weight: var(--theme-font-weight-semibold);
        }
        &-bold {
            font-weight: var(--theme-font-weight-bold);
        }
    }

    &-italic {
        font-style: italic;
    }

    &-size {
        &-14 {
            font-size: 1.4rem !important;
        }
        &-16 {
            font-size: 1.6rem !important;
        }
        &-18 {
            font-size: 1.8rem !important;
        }
        &-20 {
            font-size: 2.0rem !important;
        }
        &-24 {
            font-size: 2.4rem !important;
        }
        &-30 {
            font-size: 3.0rem !important;
        }
    }
}
