// Modifiers
.has-shadow {
    box-shadow: var(--cms-box-shadow);
}

.grey-background {
    background-color: var(--cms-grey-background);
}

.white-background {
    background-color: var(--cms-white-background);
}

// Used for blogs / articles
.theme-color-1-background {
    background-color: var(--theme-color-4);
}

.theme-color-2-background {
    background-color: var(--theme-color-5);
}

.theme-color-3-background {
    background-color: var(--theme-color-6);
}

.theme-color-4-background {
    background-color: var(--theme-color-7);
}

// General cms background colors
.cms-bg-color-1 {
    background-color: var(--theme-color-1);
}
.cms-bg-color-2 {
    background-color: var(--theme-color-2);
}
.cms-bg-color-3 {
    background-color: var(--theme-color-3);
}
.cms-bg-color-4 {
    background-color: var(--theme-color-4);
}
.cms-bg-color-5 {
    background-color: var(--theme-color-5);
}
.cms-bg-color-6 {
    background-color: var(--theme-color-6);
}
.cms-bg-color-7 {
    background-color: var(--theme-color-7);
}
.cms-bg-color-8 {
    background-color: var(--theme-color-8);
}
.cms-bg-color-9 {
    background-color: var(--theme-color-9);
}
.cms-bg-color-10 {
    background-color: var(--theme-color-10);
}
.cms-bg-color-10a {
    background-color: var(--theme-color-10a);
}
.cms-bg-color-10b {
    background-color: var(--theme-color-10b);
}
.cms-bg-color-10c {
    background-color: var(--theme-color-10c);
}
.cms-bg-color-10d {
    background-color: var(--theme-color-10d);
}
.cms-bg-color-10e {
    background-color: var(--theme-color-10e);
}
.cms-bg-color-10f {
    background-color: var(--theme-color-10f);
}
.cms-bg-color-11 {
    background-color: var(--theme-color-11);
}

// General font colors
.cms-font-color-1 {
    color: var(--theme-color-1) !important;

    &:link, &:visited {
        color: var(--theme-color-1) !important;
    }
}
.cms-font-color-2 {
    color: var(--theme-color-2) !important;
    
    &:link, &:visited {
        color: var(--theme-color-2) !important;
    }
}
.cms-font-color-3 {
    color: var(--theme-color-3) !important;

    &:link, &:visited {
        color: var(--theme-color-3) !important;
    }
}
.cms-font-color-4 {
    color: var(--theme-color-4) !important;

    &:link, &:visited {
        color: var(--theme-color-4) !important;
    }
}
.cms-font-color-5 {
    color: var(--theme-color-5) !important;

    &:link, &:visited {
        color: var(--theme-color-5) !important;
    }
}
.cms-font-color-6 {
    color: var(--theme-color-6) !important;

    &:link, &:visited {
        color: var(--theme-color-6) !important;
    }
}
.cms-font-color-7 {
    color: var(--theme-color-7) !important;

    &:link, &:visited {
        color: var(--theme-color-7) !important;
    }
}
.cms-font-color-8 {
    color: var(--theme-color-8) !important;

    &:link, &:visited {
        color: var(--theme-color-8) !important;
    }
}
.cms-font-color-9 {
    color: var(--theme-color-9) !important;

    &:link, &:visited {
        color: var(--theme-color-9) !important;
    }
}
.cms-font-color-10 {
    color: var(--theme-color-10) !important;

    &:link, &:visited {
        color: var(--theme-color-10) !important;
    }
}
.cms-font-color-10a {
    color: var(--theme-color-10a) !important;

    &:link, &:visited {
        color: var(--theme-color-10a) !important;
    }
}
.cms-font-color-10b {
    color: var(--theme-color-10b) !important;

    &:link, &:visited {
        color: var(--theme-color-10b) !important;
    }
}
.cms-font-color-10c {
    color: var(--theme-color-10c) !important;

    &:link, &:visited {
        color: var(--theme-color-10c) !important;
    }
}
.cms-font-color-10d {
    color: var(--theme-color-10d) !important;

    &:link, &:visited {
        color: var(--theme-color-10d) !important;
    }
}
.cms-font-color-10e {
    color: var(--theme-color-10e) !important;

    &:link, &:visited {
        color: var(--theme-color-10e) !important;
    }
}
.cms-font-color-10f {
    color: var(--theme-color-10f) !important;

    &:link, &:visited {
        color: var(--theme-color-10f) !important;
    }
}
.cms-font-color-11 {
    color: var(--theme-color-11) !important;

    &:link, &:visited {
        color: var(--theme-color-11) !important;
    }
}
.cms-font-color-white {
    color: var(--white) !important;

    &:link, &:visited {
        color: var(--white) !important;
    }
}
.cms-font-color-black {
    color: var(--black) !important;

    &:link, &:visited {
        color: var(--black) !important;
    }
}
.cms-font-color-primary {
    color: var(--primary-base-color) !important;

    &:link, &:visited {
        color: var(--primary-base-color) !important;
    }
}

.transparent-background {
    background-color: transparent;
}

.center-content {
    text-align: center;
}

.use-light-text {
    color: var(--cms-light-font-color);

    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--cms-light-font-color);
    }
}

.cms-spacer, .cms-col .cms-spacer {
    display: block;
    width: 100%;
    height: var(--cms-vertical-padding-x1);
    margin: 0;

    > p {
        display: none;
    }

    &.hidden-on-sm {
        @include phone {
            display: none;
        }
    }

    &.hidden-on-md {
        @include tablet {
            display: none;
        }
    }

    &.hidden-on-bd {
        @include before-desktop {
            display: none;
        }
    }

    &.hidden-on-lg {
        @include desktop {
            display: none;
        }
    }

    &.triple-spacer-height {
        height: var(--cms-vertical-padding-x3);
    }

    @include desktop {
        &.triple-spacer-height-lg {
            height: var(--cms-vertical-padding-x3);
        }
    }

    @include tablet {
        &.triple-spacer-height-md {
            height: var(--cms-vertical-padding-x3);
        }
    }

    @include before-desktop {
        &.triple-spacer-height-bd {
            height: var(--cms-vertical-padding-x3);
        }
    }

    @include phone {
        &.triple-spacer-height-sm {
            height: var(--cms-vertical-padding-x3);
        }
    }

    &.double-spacer-height {
        height: var(--cms-vertical-padding-x2);
    }

    @include desktop {
        &.double-spacer-height-lg {
            height: var(--cms-vertical-padding-x2);
        }
    }

    @include tablet {
        &.double-spacer-height-md {
            height: var(--cms-vertical-padding-x2);
        }
    }

    @include before-desktop {
        &.double-spacer-height-bd {
            height: var(--cms-vertical-padding-x2);
        }
    }

    @include phone {
        &.double-spacer-height-sm {
            height: var(--cms-vertical-padding-x2);
        }
    }

    @include desktop {
        &.single-spacer-height-lg {
            height: var(--cms-vertical-padding-x1);
        }
    }

    @include tablet {
        &.single-spacer-height-md {
            height: var(--cms-vertical-padding-x1);
        }
    }

    @include before-desktop {
        &.single-spacer-height-bd {
            height: var(--cms-vertical-padding-x1);
        }
    }

    @include phone {
        &.single-spacer-height-sm {
            height: var(--cms-vertical-padding-x1);
        }
    }
}

.cms-p-spacer, .cms-col .cms-p-spacer {
    display: block;
    width: 100%;
    height: var(--cms-p-spacing-x1);
    margin: 0;

    > p {
        display: none;
    }

    &.hidden-on-sm {
        @include phone {
            display: none;
        }
    }

    &.hidden-on-md {
        @include tablet {
            display: none;
        }
    }

    &.hidden-on-bd {
        @include before-desktop {
            display: none;
        }
    }

    &.hidden-on-lg {
        @include desktop {
            display: none;
        }
    }

    &.triple-spacer-height {
        height: var(--cms-p-spacing-x3);
    }

    @include desktop {
        &.triple-spacer-height-lg {
            height: var(--cms-p-spacing-x3);
        }
    }

    @include before-desktop {
        &.triple-spacer-height-bd {
            height: var(--cms-p-spacing-x3);
        }
    }

    @include phone {
        &.triple-spacer-height-sm {
            height: var(--cms-p-spacing-x3);
        }
    }

    &.double-spacer-height {
        height: var(--cms-p-spacing-x2);
    }

    @include desktop {
        &.double-spacer-height-lg {
            height: var(--cms-p-spacing-x2);
        }
    }

    @include before-desktop {
        &.double-spacer-height-bd {
            height: var(--cms-p-spacing-x2);
        }
    }

    @include phone {
        &.double-spacer-height-sm {
            height: var(--cms-p-spacing-x2);
        }
    }

    @include desktop {
        &.single-spacer-height-lg {
            height: var(--cms-p-spacing-x1);
        }
    }

    @include before-desktop {
        &.single-spacer-height-bd {
            height: var(--cms-p-spacing-x1);
        }
    }

    @include phone {
        &.single-spacer-height-sm {
            height: var(--cms-p-spacing-x1);
        }
    }
}

.cms-col {
    & .Button {
        @include phone {
            font-size: 1.4rem;
            margin: 0 var(--theme-spacing-8);
        }

        &.button-color {
            &-white {
                color: var(--white);
                border-color: var(--white);
    
                &:hover {
                    color: var(--black);
                    background-color: var(--white);
                    border-color: var(--white);
                }
            }
    
            &-black {
                color: var(--black);
                border-color: var(--black);
    
                &:hover {
                    color: var(--white);
                    background-color: var(--black);
                    border-color: var(--black);
                }
            }

            &-primary {
                color: var(--primary-base-color);
                border-color: var(--primary-base-color);
    
                &:hover {
                    color: var(--white);
                    background-color: var(--primary-dark-color);
                    border-color: var(--primary-dark-color);
                }
            }
        }
        
        &.button-outline {
            background-color: transparent;
        }
    }
}

.cms-image {
    &-size-original {
        @include image-reset;
    }

    &-size-full {
        & .Image:not(.Logo) {
            // height: 100%;
            // width: 100%;
            // padding-bottom: 100%;

            & .Image-Image {
                width: 100% !important;
                // height: 100% !important;
                // position: absolute;
                object-fit: contain;
            }
        }
    }

    &-round {
        border-radius: 9999px;
    }
}

.cms-text-underline {
    text-decoration: underline;
}

.cms-linebreak {
    width: 100%;
    height: 1px;
}

.cms-height {
    &-100 {
        height: 100%;
    }
}

.cms-width {
    &-100 {
        width: 100%;
    }
}