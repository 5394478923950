.CMSKifaruLodging {
    $block: &;

    display: grid;
    grid-template-columns: auto;
    gap: var(--theme-spacing-24);

    @include desktop {
        grid-template-columns: repeat(2, 1fr);
        gap: var(--theme-spacing-20);
    }

    &-Card {
        position: relative;
        height: 53rem;

        @include desktop {
            height: 73rem;
        }
    }

    &-Image {
        position: absolute;
        width: 100%;
        height: 100%;

        & .Image {
            &.Image:not(.Logo) {
                height: 100%;
                width: 100%;
        
                &.Image {
                    & .Image-Image {
                        width: 100% !important;
                        height: 100% !important;
                        object-fit: cover;
                    }
                }
            }
    
            padding-bottom: unset;
            height: 100%;
            width: 100%;
    
            & .Image-Image {
                object-fit: cover;

                @include desktop {
                    transition: transform .5s ease-out;
                }

                #{$block}-Card:hover & {
                    @include desktop {
                        transition: transform .5s ease-out;
                        transform: scale(1.05);
                    }
                 }
            }
        }

    }

    &-ImageOverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(var(--theme-color-10-gradient), var(--theme-color-10-overlay));
        pointer-events: none;
    }

    &-Bar {
        display: flex;
        width: 100%;
        position: absolute;
        bottom: var(--theme-spacing-32);
        padding: 0 var(--theme-spacing-32);
        pointer-events: none;

        @include before-desktop {
            flex-direction: column;
            bottom: var(--theme-spacing-24);
            padding: 0 var(--theme-spacing-24);
        }
    }

    &-Info {
        flex-grow: 1;
    }

    &-Title {
        #{$block} & {
            color: var(--white);
            text-shadow: var(--theme-shadow-dark);
        }
    }

    &-Subtitle {
        #{$block} & {
            color: var(--primary-base-color);
            text-shadow: var(--theme-shadow-dark);
        }
    }

    &-Actions {
        margin: 0 calc(var(--theme-spacing-8) * -1);
        pointer-events: all;

        @include before-desktop {
            display: flex;
            margin-top: var(--theme-spacing-20);
            justify-content: center;
        }
    }

    &-Link {
        @include before-desktop {
            width: 100%;
            text-align: center;
        }
    }
}