/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../style/abstract/theme_colors';
@import '../../style/abstract/theme_sizes';

@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme_mixin';

:root {
    --header-background: #fff;
    --header-total-height: calc(152px + env(safe-area-inset-top));
    --header-nav-height: 112px;
    --header-menu-height: 112px;
    --header-logo-width: 25.4rem;
    --header-logo-height: 4rem;
    --top-bar-color: var(--theme-color-10a);

    @include before-desktop {
        --header-total-height: calc(48px + 60px + env(safe-area-inset-top));
        --header-nav-height: auto;
        --header-height: var(--header-total-height);
    }
}

body {
    @include mobile {
        --header-height: var(--header-total-height);
    }

    &.is-topmenu-hidden {
        @at-root & {
            --header-total-height: calc(103px + env(safe-area-inset-top));
            @include mobile {
                --header-height: 60px;
                --header-total-height: calc(60px + env(safe-area-inset-top));
            }
        }
    }
}

.Header {
    transition: .2s ease-in all;
    @include before-desktop {
		--header-height: var(--header-total-height);
        background-color: transparent;
        // pointer-events: none;
    }

    .isHomePage & {
        @include desktop {
            background-color: transparent;
            border-bottom: 0;
        }
    }

    &_scrolled {
        @include desktop {
            --header-nav-height: 60px;
            --header-menu-height: 60px;
        }
        .isHomePage & {
            @include desktop {
                background-color: var(--black);
            }
        }
    }

    &_isHiddenOnMobile {
        opacity: 1;
        pointer-events: all;
    }

    &-Wrapper {
        --header-height: var(--header-total-height);

        @include before-desktop {
            margin-bottom: var(--header-height);
        }

        &_topMenuIsClosed {
            @at-root & {
                --header-total-height: calc(103px + env(safe-area-inset-top));
                @include mobile {
                    --header-height: 60px;
                }
            }
        }

        &_name_default {
            @include mobile {
                margin-bottom: var(--header-height);
            }
        }

        .isHomePage & {
            @include desktop {
                margin-bottom: 0;
            }
        }
    }

    &-TopMenu {
        .Header-Wrapper_topMenuIsClosed & {
            display: none;
        }
		display: flex;
        background-color: var(--top-bar-color);
        max-width: none;
        height: 49px;
        max-height: 49px;
        width: 100%;
        padding: 0;
        color: var(--white);
        z-index: 21;

        &_isHidden {
            display: none;
        }

        &Info {
            width: 100%;
			display: flex;
            align-items: center;
            margin: 0 auto;
            max-width: var(--content-wrapper-width);
            padding: 0 var(--theme-spacing-24);
            @include font-topmenu;

            &Wrapper,
			& .CmsBlock-Wrapper_type_TopMenuInfo {
                display: flex;
                flex-grow: 1;
                justify-content: space-between;

                @include mobile {
                    justify-content: center;
                }
            }

            &Item {
                text-transform: uppercase;
				max-width: 100%;
                text-align: center;

                @media only screen and (max-width: 500px) {
                    font-size: .9rem;
                }

                &:last-child {
                    // This hack makes sure that the center item is precisely center, because of the close button.
                    padding-right: calc(var(--theme-spacing-24) + var(--theme-font-size-topmenu-p));
                }

                &_isSoloCenter:last-child {
                    padding-left: calc(var(--theme-spacing-24) + var(--theme-font-size-topmenu-p));
                    width: 100%;

                    @include mobile {
                        padding-right: calc(var(--theme-spacing-16) + var(--theme-font-size-topmenu-p));
                    }
                }

                &_isHidden_onMobile {
                    @include mobile {
                        display: none;
                    }
                }
            }

            &Close {
                position: absolute;
                right: var(--theme-spacing-24);
                width: var(--theme-font-size-topmenu-p);
                min-width: var(--theme-font-size-topmenu-p);
                height: var(--theme-font-size-topmenu-p);
                min-height: var(--theme-font-size-topmenu-p);
                cursor: pointer;
                background-image: url('image/Close-icon.svg');
                background-repeat: no-repeat;
                filter: invert(100%);
            }
        }
    }

    &-LogoWrapper {
        pointer-events: all;
        
        @include desktop {
            left: var(--theme-spacing-24);
            right: auto;
            height: 150px;
            width: 150px;
            top: 0;
            bottom: auto;
            transition: .2s ease-in all;
        }

        .Header_scrolled & {
            @include desktop {
                width: 100px;
                height: 100px;
            }
        }
    }

    &-Nav {
        z-index: 22;
        transition: .2s ease-in all;
        pointer-events: none;

        @include desktop {
            padding: 0 var(--theme-spacing-24);
            background-color: transparent;
        }

        .MenuOverlay_isActive & {
            background-color: var(--overlay-background);
            display: block;

            .Header-Title {
                line-height: 2.5rem;
            }
        }

        @include mobile {
            display: none;
        }

        .Header-Wrapper_name_cart_overlay & {
            @include desktop {
                background: transparent;
            }
        }

        .isHomePage & {
            @include desktop {
                background-color: transparent;
            }
        }

        .Header_scrolled & {
            .isHomePage & {
                @include desktop {
                    background-color: transparent;
                }
            }
        }

    }

    &-MyAccount {
        margin-left: var(--theme-spacing-32);

        @media screen and (max-width: 1300px) {
            margin-left: var(--theme-spacing-16);
        }
    }

    &-Button {
        &_type {
            &_account {
                background-image: url('image/Profile-icon.svg');
                background-repeat: no-repeat;
                width: 2rem;
                height: 2rem;

                .isHomePage & {
                    @include desktop {
                        filter: invert(1);
                    }
                }
            }

            &_minicart {
                @include desktop {
                    padding: 0;
                    margin-left: var(--theme-spacing-32);
                    
                    @media screen and (max-width: 1300px) {
                        margin-left: var(--theme-spacing-16);
                    }
                }


                @include before-desktop {
                    background-image: url('image/Cart-icon.svg');
                    background-repeat: no-repeat;
                    width: 2rem;
                    height: 2rem;
                }
            }

            &_search {
                background-image: url('image/Search-icon.svg');
                background-repeat: no-repeat;
                width: 2rem;
                height: 2rem;
            }

            &_menu {
                background-image: url('image/Burgermenu-Icon.svg');
                background-repeat: no-repeat;
                width: 2rem;
                height: 2rem;
                background-position: 0 50%;

                &.NavigationTabs-Icon {
                    &_isActive {
                        background-image: url('image/Burgermenu-Icon.svg');
                        transform: none;
                    }
                }
            }

            &_back {
                .isCategoryPage & {
                    @include before-desktop {
                        //display: none;
                    }
                }

				&::before {
                    @include before-desktop {
                        height: 2px;
                    }
                }

                &::after {
                    @include before-desktop {
                        border-top-width: 2px;
                        border-left-width: 2px;
                    }
                }
            }

            &_clear {
                .isCategoryPage & {
                    @include before-desktop {
                        display: none;
                    }
                }
            }

            &_close {
                .isCategoryPage & {
                    @include before-desktop {
                        display: none;
                    }
                }
            }
        }
    }

    &-Minicart{
        &ButtonWrapper {
            margin-left: 0;
            padding-right: 0;
        }

        &Icon {
            background-image: url('image/Cart-icon.svg');
            background-repeat: no-repeat;
            width: 2rem;
            height: 2rem;

            .isHomePage & {
                @include desktop {
                    filter: invert(1);
                }
            }
        }

        &ItemCount {
            font-size: var(--theme-font-size);
            font-weight: var(--theme-font-weight-menu);

			@include before-desktop {
                left: 8px;
                top: 12px;
            }
        }
    }

    &-Title {
        font-family: var(--theme-font-family-secondary);
        font-size: var(--theme-font-size-menu-mobile);
        font-weight: var(--theme-font-weight-menu-mobile);
        text-transform: none;
        height: var(--theme-font-size-menu-mobile);
        //line-height: var(--theme-font-size-menu-mobile);
    }
}