/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../style/abstract/theme_colors';
@import '../../../../style/abstract/theme_sizes';

@import '../../../../style/abstract/font';
@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
@import '../../../../style/abstract/theme_mixin';

.SubscriptionActions {
    &-OneTimePayment {
        margin-bottom: var(--theme-spacing-20);
        background: transparent;
        border: 1px solid var(--input-border-color);
        display: flex;
        align-items: center;
        padding: var(--input-padding);
    
        & > div{
            margin-top: 0;
        }
    
        & > p { 
            margin-bottom: 0;
            line-height: unset;
            font-size: var(--theme-font-size-p);

            &:first-of-type {
                flex-grow: 1;
            }
        }
    
        & .Field_name_OneTimePayment_Toggle {
            margin-top: 0;
            margin-right: var(--theme-spacing-8);
    
            & .input-control {
                margin-left: 0;
            }
        }
    }

    &-Subscription {
        border: 1px solid var(--input-border-color);
    
        &Top {
            background: var(--theme-color-7);
            display: flex;
            align-items: center;
            padding: var(--input-padding);
            
            & > div{
                margin-top: 0;
            }
        
            & > p {
                margin-bottom: 0;
                line-height: unset;
                font-size: var(--theme-font-size-p);
    
                &:first-of-type {
                    flex-grow: 1;
                }
            }
    
            & .Field_name_Subscription_Toggle {
                margin-top: 0;
                margin-right: var(--theme-spacing-8);
    
                & .input-control {
                    margin-left: 0;
                }
            }
        }
    
        li {
            color: $black;
        }
    }

    &-SubscriptionOption {
        display: none;
        padding: var(--input-padding);

        & > p {
            line-height: unset;
            margin-bottom: 0;
        }
    }

    &-FromPrice {
        margin-right: 5px;
    }

    & .ProductPrice {
        @include font-product-price;

        display: flex;
        flex-direction: column;
        line-height: unset;
        text-align: right;
    }
}
