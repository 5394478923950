.cms-appetizer {
    .cms-col & {
        font-family: var(--theme-font-family-primary);
        font-weight: 500;
        text-align: center;
        
        font-size: 2.9rem;
        line-height: 3.4rem;

        @include before-desktop {
            font-size: 1.8rem;
            line-height: 2.4rem;
        }
    }
}

.cms-header-underlay {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 1200px;
    z-index: -1;
}

.cms-header-underlay-image, .cms-bg-third {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 35%;
    z-index: -1;
}

.cms-grid {
    display: grid;
    width: 100%;

    &.grid-col-1 { grid-template-columns: 1fr; }
    &.grid-col-2 { grid-template-columns: repeat(2, 1fr); }
    &.grid-col-3 { grid-template-columns: repeat(3, 1fr); }
    &.grid-col-4 { grid-template-columns: repeat(4, 1fr); }
    &.grid-col-5 { grid-template-columns: repeat(5, 1fr); }
    &.grid-col-6 { grid-template-columns: repeat(6, 1fr); }

    @include desktop {
        &.grid-col-1-lg { grid-template-columns: 1fr; }
        &.grid-col-2-lg { grid-template-columns: repeat(2, 1fr); }
        &.grid-col-3-lg { grid-template-columns: repeat(3, 1fr); }
        &.grid-col-4-lg { grid-template-columns: repeat(4, 1fr); }
        &.grid-col-5-lg { grid-template-columns: repeat(5, 1fr); }
        &.grid-col-6-lg { grid-template-columns: repeat(6, 1fr); }
    }

    @include before-desktop {
        &.grid-col-1-bd { grid-template-columns: 1fr; }
        &.grid-col-2-bd { grid-template-columns: repeat(2, 1fr); }
        &.grid-col-3-bd { grid-template-columns: repeat(3, 1fr); }
        &.grid-col-4-bd { grid-template-columns: repeat(4, 1fr); }
        &.grid-col-5-bd { grid-template-columns: repeat(5, 1fr); }
        &.grid-col-6-bd { grid-template-columns: repeat(6, 1fr); }
    }

    @include tablet {
        &.grid-col-1-md { grid-template-columns: 1fr; }
        &.grid-col-2-md { grid-template-columns: repeat(2, 1fr); }
        &.grid-col-3-md { grid-template-columns: repeat(3, 1fr); }
        &.grid-col-4-md { grid-template-columns: repeat(4, 1fr); }
        &.grid-col-5-md { grid-template-columns: repeat(5, 1fr); }
        &.grid-col-6-md { grid-template-columns: repeat(6, 1fr); }
    }

    @include phone {
        &.grid-col-1-sm { grid-template-columns: 1fr; }
        &.grid-col-2-sm { grid-template-columns: repeat(2, 1fr); }
        &.grid-col-3-sm { grid-template-columns: repeat(3, 1fr); }
        &.grid-col-4-sm { grid-template-columns: repeat(4, 1fr); }
        &.grid-col-5-sm { grid-template-columns: repeat(5, 1fr); }
        &.grid-col-6-sm { grid-template-columns: repeat(6, 1fr); }
    }

    &.gap-single {
        gap: var(--cms-spacing-x1);
    }
    &.gap-double {
        gap: var(--cms-spacing-x2);
    }
    &.gap-triple {
        gap: var(--cms-spacing-x3);
    }
    &.gap-single-h {
        column-gap: var(--cms-spacing-x1);
    }
    &.gap-double-h {
        column-gap: var(--cms-spacing-x2);
    }
    &.gap-triple-h {
        column-gap: var(--cms-spacing-x3);
    }
    &.gap-single-v {
        row-gap: var(--cms-spacing-x1);
    }
    &.gap-double-v {
        row-gap: var(--cms-spacing-x2);
    }
    &.gap-triple-v {
        row-gap: var(--cms-spacing-x3);
    }
}

.cms-flex {
    display: flex;
    flex-wrap: wrap;

    &.no-wrap {
        flex-wrap: nowrap;
    }

    &.no-outer-margin, &:not(.direction-col).no-outer-margin {
        & > *:first-child { margin-left: 0; } 
        & > *:last-child { margin-right: 0; }
    }

    &:not(.direction-col) {
        &.gap-single {
            & > * { margin: 0 calc(var(--cms-spacing-x1) / 2); }
        }

        &.gap-single-sm {
            @include phone {
                & > * { margin: 0 calc(var(--cms-spacing-x1) / 2); }
            }
        }

        &.gap-single-md {
            @include tablet {
                & > * { margin: 0 calc(var(--cms-spacing-x1) / 2); }
            }
        }

        &.gap-single-bd {
            @include before-desktop {
                & > * { margin: 0 calc(var(--cms-spacing-x1) / 2); }
            }
        }

        &.gap-single-lg {
            @include desktop {
                & > * { margin: 0 calc(var(--cms-spacing-x1) / 2); }
            }
        }

        &.gap-double {
            & > * { margin: 0 calc(var(--cms-spacing-x2) / 2); }
        }

        &.gap-double-sm {
            @include phone {
                & > * { margin: 0 calc(var(--cms-spacing-x2) / 2); }
            }
        }

        &.gap-double-md {
            @include tablet {
                & > * { margin: 0 calc(var(--cms-spacing-x2) / 2); }
            }
        }

        &.gap-double-bd {
            @include before-desktop {
                & > * { margin: 0 calc(var(--cms-spacing-x2) / 2); }
            }
        }

        &.gap-double-lg {
            @include desktop {
                & > * { margin: 0 calc(var(--cms-spacing-x2) / 2); }
            }
        }

        &.gap-triple {
            & > * { margin: 0 calc(var(--cms-spacing-x3) / 2); }
        }

        &.gap-triple-sm {
            @include phone {
                & > * { margin: 0 calc(var(--cms-spacing-x3) / 2); }
            }
        }

        &.gap-triple-md {
            @include tablet {
                & > * { margin: 0 calc(var(--cms-spacing-x3) / 2); }
            }
        }

        &.gap-triple-bd {
            @include before-desktop {
                & > * { margin: 0 calc(var(--cms-spacing-x3) / 2); }
            }
        }

        &.gap-triple-lg {
            @include desktop {
                & > * { margin: 0 calc(var(--cms-spacing-x3) / 2); }
            }
        }
    }

    &.direction-col {
        flex-direction: column;

        &.gap-single {
            & > * { margin: calc(var(--cms-spacing-x1) / 2) 0; }
        }

        &.gap-single-sm {
            @include phone {
                & > * { margin: calc(var(--cms-spacing-x1) / 2) 0; }
            }
        }

        &.gap-single-md {
            @include tablet {
                & > * { margin: calc(var(--cms-spacing-x1) / 2) 0; }
            }
        }

        &.gap-single-bd {
            @include before-desktop {
                & > * { margin: calc(var(--cms-spacing-x1) / 2) 0; }
            }
        }

        &.gap-single-lg {
            @include desktop {
                & > * { margin: calc(var(--cms-spacing-x1) / 2) 0; }
            }
        }

        &.gap-double {
            & > * { margin: calc(var(--cms-spacing-x2) / 2) 0; }
        }

        &.gap-double-sm {
            @include phone {
                & > * { margin: calc(var(--cms-spacing-x2) / 2) 0; }
            }
        }

        &.gap-double-md {
            @include tablet {
                & > * { margin: calc(var(--cms-spacing-x2) / 2) 0; }
            }
        }

        &.gap-double-bd {
            @include before-desktop {
                & > * { margin: calc(var(--cms-spacing-x2) / 2) 0; }
            }
        }

        &.gap-double-lg {
            @include desktop {
                & > * { margin: calc(var(--cms-spacing-x2) / 2) 0; }
            }
        }

        &.gap-triple {
            & > * { margin: calc(var(--cms-spacing-x3) / 2) 0; }
        }

        &.gap-triple-sm {
            @include phone {
                & > * { margin: calc(var(--cms-spacing-x3) / 2) 0; }
            }
        }

        &.gap-triple-md {
            @include tablet {
                & > * { margin: calc(var(--cms-spacing-x3) / 2) 0; }
            }
        }

        &.gap-triple-bd {
            @include before-desktop {
                & > * { margin: calc(var(--cms-spacing-x3) / 2) 0; }
            }
        }

        &.gap-triple-lg {
            @include desktop {
                & > * { margin: calc(var(--cms-spacing-x3) / 2) 0; }
            }
        }
    }
}

.cms-socialmedia {
    & .CMSMenuOverlaySocialMedia {
        @include before-desktop {
            justify-content: center;
        }
    }

    & .CMSMenuOverlaySocialMedia-Link {
        @include desktop {
            padding: var(--theme-spacing-24);
        }
    }

    & .CMSMenuOverlaySocialMedia-Icon {
        @include desktop {
            width: var(--theme-spacing-24);
            height: var(--theme-spacing-24);
        } 
    }
}

.cms-image-overlay-gradient {
    position: absolute;
    background-image: linear-gradient(var(--theme-color-10-gradient), var(--theme-color-10));
    width: 100%;
    height: 100%;
}

.cms-image-overlay-gradient-frontpage-hero {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), var(--theme-color-10-gradient), var(--theme-color-10));
    
    @include desktop {
        background-image: linear-gradient(rgba(0, 0, 0, 0.4), var(--theme-color-10-gradient), var(--theme-color-10));
    }
}

.cms-margin-top-negative {
    margin-top: -14rem;

    &-sm {
        @include phone {
            margin-top: -14rem;
        }
    }

    &-md {
        @include tablet {
            margin-top: -14rem;
        }
    }

    &-bd {
        @include before-desktop {
            margin-top: -14rem;
        }
    }

    &-lg {
        @include desktop {
            margin-top: -14rem;
        }
    }
}

.cms-image-portrait-round {
    & .Image:not(.Logo) {
        border-radius: 9999px;
        width: 70px;
        height: 70px;

        & .Image-Image {
            width: 100% !important;
            object-fit: unset !important;
        }
    }
}

.cms-flex-menu {
    display: flex;
    flex-wrap: wrap;

    @include before-desktop {
       flex-wrap: wrap; 
       flex-direction: column;
    }

    & > * {
        @include before-desktop {
            width: 100%;
        }

        @include desktop {
            &:first-child a { padding-left: 0; }
            &:last-child a { padding-right: 0; }
        }

        & a {
            display: block;
            font-size: 1.6rem;
            color: var(--black);
            font-weight: var(--theme-font-weight-semibold);

            @include before-desktop {
                width: 100%;
                padding: var(--theme-spacing-16) 0;
            }

            @include desktop {
                padding: var(--theme-spacing-48) var(--theme-spacing-24);
            }

            &:hover, &:focus {
                @include before-desktop {
                    text-decoration: none;
                }
            }
        }
    }
}

.cms-clerk-title-hide {
    & .Clerk-Title {
        display: none;
    }
}
