.CMSFooterCertificates {
    display: flex;

    & .Image {
        padding: 0 var(--theme-spacing-8);
        display: block;
        height: auto;
        overflow: auto;

        & > .Image-Image {
            position: static;
        }
    }

    & > div {
        margin-left: var(--theme-spacing-20);
    }
}