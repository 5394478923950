/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../hoeks-kifaru-pwa/src/style/abstract/theme_colors';
@import '../../../../hoeks-kifaru-pwa/src/style/abstract/theme_sizes';

@import '../../../../hoeks-kifaru-pwa/src/style/abstract/font';
@import '../../../../hoeks-kifaru-pwa/src/style/abstract/variables';
@import '../../../../hoeks-kifaru-pwa/src/style/abstract/media';
@import '../../../../hoeks-kifaru-pwa/src/style/abstract/button';
@import '../../../../hoeks-kifaru-pwa/src/style/abstract/loader';
@import '../../../../hoeks-kifaru-pwa/src/style/abstract/icons';
@import '../../../../hoeks-kifaru-pwa/src/style/abstract/parts';
@import '../../../../hoeks-kifaru-pwa/src/style/abstract/theme_mixin';

.Menu {
    &-MenuWrapper {
        @include desktop {
            height: var(--header-menu-height);
            min-height: auto;
            margin: 0;
            z-index: 1;
        }
    }

    &-ItemFigure {
        &_type_main {
            @include desktop {
                height: var(--header-menu-height);
            }
        }
    }

    &-ItemList {
        &_type_main {
            @include desktop {
                width: 100%;
                justify-content: center;
            }
        }
    }

    &-MainCategories {
        @include desktop {
            padding-right: 1.2rem;
        }
    }

    &-SubCategoriesWrapper {
        @include desktop {
            top: var(--header-menu-height);
        }
    }

    &-CompareLinkWrapper {
        display: none;
    }

}