/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../style/abstract/theme_colors';
@import '../../style/abstract/theme_sizes';

@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme_mixin';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --menu-background-color: var(--white);
    --menu-desktop-background-color: rgba(122, 122, 122, .44);
    --menu-desktop-height: 60px;
    --menu-item-figure-background: var(--secondary-base-color);
    --menu-item-hover-color: var(--primary-base-color);
    --menu-second-level-color: #adadad;
    --menu-box-shadow-color: rgba(0, 0, 0, 0.1);

    @include before-desktop {
        --menu-background-color: var(--theme-color-7);
    }
}

.Menu {
    $block: &;
    &-MenuWrapper {
        @include desktop {
            position: relative;
            margin-top: -112px;
            z-index: 21;
            //width: fit-content;
            margin-left: auto;
            margin-right: auto;
            pointer-events: none;
            transition: all .2s ease-in;
        }

        @include before-desktop {
            padding: var(--theme-spacing-32) var(--theme-spacing-24) var(--theme-spacing-24);
        }

        .Header_scrolled & {
            @include desktop {
                transition: all .2s ease-in;
                margin-top: -60px;
            }
        }

        .Header-Wrapper_name_cart_overlay & {
            @include desktop {
                z-index: 20;
            }
        }
    }

    &-MainCategories {
        @include desktop {
            width: min-content;
            pointer-events: auto;
            padding: 0;
        }

		.StoreSwitcher {
			display: none;
		}
    }

    &-ItemList {
        @include desktop {
            justify-content: center;
        }

        @include before-desktop {
            order: 2;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            padding: 0;
            padding-bottom: calc(var(--menu-select-height) * 2);
            height: calc(var(--menu-select-height) * 2);
        }

        &_type_main {
            @include before-desktop {
                //align-items: center;
            }
        }

        &_type_subcategory {
            background-color: transparent;

            @include before-desktop {
                align-items: center;
                justify-content: flex-start;
				width: 100%;
            }

            & #{$block}-ItemFigure {
                @include before-desktop {
                    --menu-item-height: 16px;
                }
            }

            & #{$block}-ItemCaption {
                @include before-desktop {
                    font-size: var(--theme-font-size-menu2-mobile);
                    font-weight: var(--theme-font-weight-menu2-mobile);
                }
            }
        }

        &:not(.Menu-ItemList_type_main) {
            @include before-desktop {
                // NOTE(Bjarne): Use of !important; base style is more specific than override style for some reason.
                margin-top: 0 !important;
            }
        }

        //&_type_subLevelDesktop {} // This block is styled further down.
    }

    &-Item {
        padding: 0 var(--theme-spacing-16);

        @media screen and (max-width: 1300px) {
            padding: 0 var(--theme-spacing-8) 0;
        }

        @include before-desktop {
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
            width: 100%;
        }

        &:first-child {
            @include desktop {
                padding-left: var(--theme-spacing-16);
            }
        }
    }

    &-Link {
        &:hover, &_isHovered, &:focus, &:active {
            & #{$block}-ItemCaption {
                @include desktop {
                    text-decoration: underline;
                    text-underline-offset: 3px;
                    //border-bottom: 1px solid var(--theme-color-10);
                }

                @include before-desktop {
                    color: var(--black);
                }
            }
        }
    }

    &-ItemFigure {
        @include before-desktop {
            --menu-item-height: 40px;
            height: calc(var(--menu-item-height) * 2);
            padding: 0 9.6rem;
        }

        @include before-desktop {
            --menu-item-height: 24px;
            padding: 0;
        }

        &_type_main{
            //text-transform: none;
            transition: all .2s ease-in;

            @include before-desktop {
                //background-color: var(--primary-base-color);
                border: none;
                //color: var(--white);
                grid-template-columns: auto;
            }
        }

        &_isGridImage {
            height: auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            margin: 0 auto;

            & .Image-Image {
                position: relative;
            }
        }
    }

    &-ItemCaption {
        //font-weight: var(--header-font-weight);

        @include desktop {
            font-size: var(--theme-font-size-menu);
            font-weight: var(--theme-font-weight-menu);
            color: var(--theme-color-10);
            transition: all .2s ease-in;
            //border-bottom: 1px solid transparent;
            .isHomePage & {
                @include desktop {
                    color: var(--white);
                }
            }
        }

        @include before-desktop {
            //font-size: 2.6rem;
            //font-weight: normal;
            text-align: center;
        }

        @include mobile {
            //font-size: 2rem;
        }

        &_type_main {
            @include before-desktop {
                font-family: var(--theme-font-family-secondary);
                font-size: var(--theme-font-size-menu-mobile);
                font-weight: var(--theme-font-weight-menu-mobile);
                text-transform: none;
            }
        }

        &_type_subcategory {
            @include before-desktop {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        &_isGridImage {
            //line-height: 3.6rem;
            margin-top: var(--theme-spacing-16);
            font-size: var(--theme-font-size-menu2-p);
            font-weight: var(--theme-font-weight-menu2-p);
            font-family: var(--theme-font-family-secondary);
        }
    }

    &-Overlay {
        background-color: transparent;
    }

    @keyframes expandMenu {
        from {
            transform: translateY(-100%);
        }
    }

	@keyframes subMenuFadeIn {
        from {
            opacity: 0;
        }
    }
    
    &-SubCategoriesWrapper {
        left: 0;
        width: 100%;
        border-top: 1px solid var(--primary-divider-color);
        top: calc(var(--header-nav-height) + 0px);
        pointer-events: auto;
        z-index: 19;
        overflow-x: visible;
        animation: subMenuFadeIn .1s ease-out;
		opacity: 1;
		transition: all .2s ease-in;

		.isHomePage & {
			@include desktop {
				border-top: 1px solid rgba(255, 255, 255, 0.2);
			}
		}

		.isHomePage .Header_scrolled & {
			@include desktop {
				border-top: 1px solid var(--theme-color-10a);
			}
		}
        
        &::after { //Drop shadow for submenu
            @include desktop {
                content: '';
                position: absolute;
                pointer-events: none;
                width: 110%;
                height: 100%;
                left: 50%;
                transform: translateX(-50%);
                top: 0;
                box-shadow: 0 8px 8px -8px var(--menu-box-shadow-color);
                z-index: 19;
            }

			.isHomePage & {
				@include desktop {
					content: none;
				}
			}

			.isHomePage .Header_scrolled & {
				@include desktop {
					content: '';
				}
			}
        }
    }

    &-SubCategoriesWrapperInner {
        transition: all .2s ease-in;
        overflow-y: visible;
        clip-path: none;
        max-height: none;
        will-change: auto;
        margin: 0;

		.isHomePage & {
			@include desktop {
				background-color: transparent;
			}
		}

		.isHomePage .Header_scrolled & {
			@include desktop {
				background-color: var(--black);
			}
		}
    }

    &-SubCategories {
        padding: 0;
        //padding-left: calc(24px + 60px + 24px);
        max-width: none;
        margin: 0;
    }

    &-SubMenu {
        @include before-desktop {
			--navigation-tabs-height: 72px;
			height: calc(100vh - var(--header-total-height) - var(--navigation-tabs-height));
            min-height: calc(100vh - var(--header-total-height) - var(--navigation-tabs-height));
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: flex-start;
			align-items: center;
			padding-top: 12px;
            padding-bottom: var(--theme-spacing-48);
            transform: none;
            transition: none;
			margin-top: calc(var(--header-total-height) + var(--navigation-tabs-height));
        }

        &_isVisible {
            @include before-desktop {
                transform: none;
                transition: none;
            }
        }

        &_isVisible figure#{$block}-ItemFigure {
                @include before-desktop {
                    opacity: 1;
                    visibility: visible;
                }  
        }

        & #{$block}-ItemFigure {
            @include before-desktop {
                opacity: 0;
                visibility: hidden;
                transition: opacity 200ms linear;
            }  
        }
    }

    // &-ItemList{} <-- Check further up

    &-SubItemWrapper {
        position: relative;
        display: flex;
        flex-basis: auto;
        flex-direction: column;
        
        @include desktop {
            //height: 6.4rem;
            margin: 0;
        }

        &_isHideOnDesktop {
            @include desktop {
                display: none;
            }
        }

        &:hover, &_isHovered, &:focus, &:active {
            & > #{$block}-Link #{$block}-ItemCaption {
                @include desktop {
                    text-decoration: underline;
                    text-underline-offset: 3px;
                    //border-bottom: 1px solid var(--theme-color-10);
                }
            }
        }
 
        &:hover #{$block}-SubLevelDesktop {
            display: flex;
        }

        & #{$block}-Link {
            //text-transform: none;
            display: flex;
            
            @include desktop {
                color: var(--black);
                height: 6rem;
                padding: 0 var(--theme-spacing-16);
            }
            
            @include before-desktop {
                padding: 0;
            }

            &:hover, &_isHovered, &:focus, &:active {
                @include desktop {
                    color: var(--black);
                }
            }
        }

        & #{$block}-ItemCaption {
            @include desktop {
                line-height: 2.4rem;
            }

            @include before-desktop {
                
            }
        }

        @include desktop {
            &.Columns1 #{$block}-ItemList_type_subLevelDesktop { grid-template-columns: 1fr; }
            &.Columns2 #{$block}-ItemList_type_subLevelDesktop { grid-template-columns: repeat(2, 1fr); }
            &.Columns3 #{$block}-ItemList_type_subLevelDesktop { grid-template-columns: repeat(3, 1fr); }
            &.Columns4 #{$block}-ItemList_type_subLevelDesktop { grid-template-columns: repeat(4, 1fr); }
            &.Columns5 #{$block}-ItemList_type_subLevelDesktop { grid-template-columns: repeat(5, 1fr); }
            &.Columns6 #{$block}-ItemList_type_subLevelDesktop { grid-template-columns: repeat(6, 1fr); }
        }
    }

    &-SubLevelDesktop {
        position: fixed;
        top: 6rem;
        left: 0;
        background-color: var(--white);
        width: 100vw;
        display: none;
        justify-content: center;
        z-index: 18;
        overflow-x: visible;
        //animation: expandMenu .1s ease-in-out;
        
        &::after { //Drop shadow for submenu
            @include desktop {
                content: '';
                position: absolute;
                pointer-events: none;
                width: 110%;
                height: 100%;
                left: 50%;
                transform: translateX(-50%);
                top: 0;
                box-shadow: 0 8px 8px -8px var(--menu-box-shadow-color);
                z-index: 18;
            }
        }

        & .Menu-ItemList {
            display: grid;
        }

        & #{$block}-Link {
            height: auto;
            margin: 0;
            //grid-area: m1;
            padding: var(--theme-spacing-8) var(--theme-spacing-16);
        }
    }

    &-ItemList_type_subLevelDesktop {
        grid-auto-flow: column;
        grid-auto-columns: minmax(0, 1fr);
        
        @include desktop {
            min-height: fit-content;
            max-width: var(--content-wrapper-width);
            margin: var(--theme-spacing-64) var(--theme-spacing-8); // margin 8 + padding 16 from the menu items themselves.
        }

        @include desktop {
            &.Columns1 { grid-template-columns: 1fr; }
            &.Columns2 { grid-template-columns: repeat(2, 1fr); }
            &.Columns3 { grid-template-columns: repeat(2, 1fr); }
            &.Columns4 { grid-template-columns: repeat(2, 1fr); }
            &.Columns5 { grid-template-columns: repeat(2, 1fr); }
            &.Columns6 { grid-template-columns: repeat(2, 1fr); }
        }
    }

    &-SubLevelDesktopItemWrapper {
        @include desktop {
            &.Column1 { grid-column-start: 1; }
            &.Column2 { grid-column-start: 2; }
            &.Column3 { grid-column-start: 3; }
            &.Column4 { grid-column-start: 4; }
            &.Column5 { grid-column-start: 5; }
            &.Column6 { grid-column-start: 6; }

            &.GridImage { grid-row: span 8; }

            &.Span2 { grid-column-end: span 2; }
        }

        & .GridImage {
            &:hover {
                & #{$block}-ItemCaption {
                    @include desktop {
                        //text-decoration: underline;
                        text-underline-offset: 1px;
                        //border-bottom: 1px solid transparent;
                    }
                }
            }

            & #{$block}-ItemCaption {
                @include desktop {
                    width: fit-content;
                    display: inline;
                    white-space: pre-wrap;
                }
            }
        }
    }

    &-Image {
        &_isGridImage {
            height: 20rem;
            width: 30rem;

            & .Image-Image {
                position: relative;
                object-fit: cover;
            }

            // & #{$block}-ItemCaption {
            //     @include desktop {
            //         text-decoration: underline;
            //         text-underline-offset: 6px;
            //         //border-bottom: 1px solid var(--theme-color-10);
            //     }
            // }
        }
    }
    
    &-Promotion {
        display: none;
    }

	&-SubCatLink, &-SubMenu > .Menu-ItemList > div {

		& > .Menu-ItemFigure {
			@include before-desktop {
				&::after {
					content: '';
					display: block;
					position: absolute;
					right: 2px;
					width: 8px;
					height: 8px;
					border-right: 2px solid var(--black);
					border-top: 2px solid var(--black);
					transform: rotateZ(45deg);
				}
			}
		}
	}

	&-SubMenu > .Menu-ItemList > div {
		@include before-desktop {
			width: 100%;
			padding: 0 24px;
			align-items: center;
		}
	}
}