/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --button-padding: 14px 22px;
    --button-color: var(--white);
    --button-background: var(--primary-base-color);
    --button-border: var(--primary-base-color);
    --button-hover-background: var(--primary-light-color);
    --button-hover-color: var(--white);
    --button-hover-border: var(--primary-light-color);
    --button-border-width: 1px;
    --button-font-weight: 600;
    --button-font-size: 14px;
}

.Button {
    @include button;
}

button {
    background-color: transparent;
}
