.CMSProductActionsOrderShippingInfo {
    display: flex;
    flex-direction: column;

    &-Item {
        display: flex;
        margin-bottom: var(--theme-spacing-8);
        font-size: var(--theme-font-size-p);

        &:last-child {
            margin-bottom: 0;
        }

        & a {
            color: var(--black);
            text-decoration: underline;
            text-underline-offset: 3px;
        }

        & > .Image {
            width: var(--theme-spacing-20);
            padding-bottom: var(--theme-spacing-20);
            margin-right: var(--theme-spacing-8);
            
            & > img {
                width: var(--theme-spacing-20) !important;
                height: var(--theme-spacing-20) !important;
            }
        } 
    }
}