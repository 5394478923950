// 12 Column Layout
.cms-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: var(--cms-full-width);
    max-width: var(--cms-full-max-width);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    > .cms-section-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: var(--cms-width);
        max-width: var(--cms-max-width);
        margin-left: auto;
        margin-right: auto;

        &.full {
            width: var(--cms-full-width);
            max-width: var(--cms-full-max-width);
        }

        &.content-wrapper {
            width: var(--cms-content-width);
            max-width: var(--cms-content-max-width);
            padding: 0 var(--cms-spacing-x2);
        }
    }

    &.cms-vertical-section-padding {
        padding-top: var(--cms-vertical-padding-x1);
        padding-bottom: var(--cms-vertical-padding-x1);
    }

    @include desktop {
        &.cms-vertical-section-padding-lg {
            padding-top: var(--cms-vertical-padding-x1);
            padding-bottom: var(--cms-vertical-padding-x1);
        }
    }

    @include before-desktop {
        &.cms-vertical-section-padding-bd {
            padding-top: var(--cms-vertical-padding-x1);
            padding-bottom: var(--cms-vertical-padding-x1);
        }
    }

    @include phone {
        &.cms-vertical-section-padding-sm {
            padding-top: var(--cms-vertical-padding-x1);
            padding-bottom: var(--cms-vertical-padding-x1);
        }
    }

    &.cms-double-vertical-section-padding {
        padding-top: var(--cms-vertical-padding-x2);
        padding-bottom: var(--cms-vertical-padding-x2);
    }

    @include desktop {
        &.cms-double-vertical-section-padding-lg {
            padding-top: var(--cms-vertical-padding-x2);
            padding-bottom: var(--cms-vertical-padding-x2);
        }
    }

    @include before-desktop {
        &.cms-double-vertical-section-padding-bd {
            padding-top: var(--cms-vertical-padding-x2);
            padding-bottom: var(--cms-vertical-padding-x2);
        }
    }

    @include phone {
        &.cms-double-vertical-section-padding-sm {
            padding-top: var(--cms-vertical-padding-x2);
            padding-bottom: var(--cms-vertical-padding-x2);
        }
    }

    &.cms-triple-vertical-section-padding {
        padding-top: var(--cms-vertical-padding-x3);
        padding-bottom: var(--cms-vertical-padding-x3);
    }

    @include desktop {
        &.cms-triple-vertical-section-padding-lg {
            padding-top: var(--cms-vertical-padding-x3);
            padding-bottom: var(--cms-vertical-padding-x3);
        }
    }

    @include before-desktop {
        &.cms-triple-vertical-section-padding-bd {
            padding-top: var(--cms-vertical-padding-x3);
            padding-bottom: var(--cms-vertical-padding-x3);
        }
    }

    @include phone {
        &.cms-triple-vertical-section-padding-sm {
            padding-top: var(--cms-vertical-padding-x3);
            padding-bottom: var(--cms-vertical-padding-x3);
        }
    }
}

.cms-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    width: calc(100% + (var(--cms-spacing-x1) * 2));
    margin: 0 calc(var(--cms-spacing-x1) * -1);

    &::after {
        content: '';
        display: table;
        clear: both;
    }

    &.reverse {
        flex-direction: row-reverse;
    }

    &.reverse-sm {
        @include phone {
            flex-wrap: wrap-reverse;
        }
    }

    > .cms-col {
        padding: 0 var(--cms-spacing-x1);

        @include before-desktop {
            width: 100%;
        }

        &.full {
            width: 100%;
        }

        &.limit-width, & .cms-limit-width {
            max-width: var(--cms-col-max-width);
        }

        &.limit-width-small, & .cms-limit-width-small {
            max-width: var(--cms-col-max-width-small);
        }

        &.limit-width-smaller, & .cms-limit-width-smaller {
            max-width: var(--cms-col-max-width-smaller);
        }

        &.cover-image {
            overflow: clip;
    
            & .Image:not(.Logo) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
    
                & .Image-Image {
                    object-fit: cover !important;
                    object-position: center;
                    width: 100% !important;
                    height: 100% !important;                
                }
            }
        }
    }

    &.has-vertical-col-spacing {
        margin-bottom: calc((var(--cms-spacing-x1) * -1) * 2);

        > .cms-col {
            margin-bottom: calc(var(--cms-spacing-x1) * 2);
        }
    }

    &.double-col-spacing {
        width: calc(100% + (var(--cms-spacing-x2) * 2));
        margin: 0 calc(var(--cms-spacing-x2) * -1);

        > .cms-col {
            padding: 0 var(--cms-spacing-x2);
        }

        &.has-vertical-col-spacing {
            margin-bottom: calc((var(--cms-spacing-x2) * -1) * 2);

            > .cms-col {
                margin-bottom: calc(var(--cms-spacing-x2) * 2);
            }
        }
    }

    &.triple-col-spacing {
        width: calc(100% + (var(--cms-spacing-x3) * 2));
        margin: 0 calc(var(--cms-spacing-x3) * -1);

        > .cms-col {
            padding: 0 var(--cms-spacing-x3);
        }

        &.has-vertical-col-spacing {
            margin-bottom: calc((var(--cms-spacing-x3) * -1) * 2);

            > .cms-col {
                margin-bottom: calc(var(--cms-spacing-x3) * 2);
            }
        }
    }

    &.equal-height-cols {
        > .cms-col {
            > .cms-col-inner {
                height: 100%;
            }
        }
    }

    &.center-cols {
        justify-content: center;
    }

    &.right-cols {
        justify-content: flex-end;
    }
}

.cms-col.cms-col-1 { width: ((100% / 12) * 1); }
.cms-col.cms-col-2 { width: ((100% / 12) * 2); }
.cms-col.cms-col-3 { width: ((100% / 12) * 3); }
.cms-col.cms-col-4 { width: ((100% / 12) * 4); }
.cms-col.cms-col-5 { width: ((100% / 12) * 5); }
.cms-col.cms-col-6 { width: ((100% / 12) * 6); }
.cms-col.cms-col-7 { width: ((100% / 12) * 7); }
.cms-col.cms-col-8 { width: ((100% / 12) * 8); }
.cms-col.cms-col-9 { width: ((100% / 12) * 9); }
.cms-col.cms-col-10 { width: ((100% / 12) * 10); }
.cms-col.cms-col-11 { width: ((100% / 12) * 11); }
.cms-col.cms-col-12 { width: ((100% / 12) * 12); }

@include desktop {
    .cms-col.cms-col-1-lg { width: ((100% / 12) * 1); }
    .cms-col.cms-col-2-lg { width: ((100% / 12) * 2); }
    .cms-col.cms-col-3-lg { width: ((100% / 12) * 3); }
    .cms-col.cms-col-4-lg { width: ((100% / 12) * 4); }
    .cms-col.cms-col-5-lg { width: ((100% / 12) * 5); }
    .cms-col.cms-col-6-lg { width: ((100% / 12) * 6); }
    .cms-col.cms-col-7-lg { width: ((100% / 12) * 7); }
    .cms-col.cms-col-8-lg { width: ((100% / 12) * 8); }
    .cms-col.cms-col-9-lg { width: ((100% / 12) * 9); }
    .cms-col.cms-col-10-lg { width: ((100% / 12) * 10); }
    .cms-col.cms-col-11-lg { width: ((100% / 12) * 11); }
    .cms-col.cms-col-12-lg { width: ((100% / 12) * 12); }

    .hidden-on-lg {
        display: none;
    }
}

@include before-desktop {
    .cms-col.cms-col-1-bd { width: ((100% / 12) * 1); }
    .cms-col.cms-col-2-bd { width: ((100% / 12) * 2); }
    .cms-col.cms-col-3-bd { width: ((100% / 12) * 3); }
    .cms-col.cms-col-4-bd { width: ((100% / 12) * 4); }
    .cms-col.cms-col-5-bd { width: ((100% / 12) * 5); }
    .cms-col.cms-col-6-bd { width: ((100% / 12) * 6); }
    .cms-col.cms-col-7-bd { width: ((100% / 12) * 7); }
    .cms-col.cms-col-8-bd { width: ((100% / 12) * 8); }
    .cms-col.cms-col-9-bd { width: ((100% / 12) * 9); }
    .cms-col.cms-col-10-bd { width: ((100% / 12) * 10); }
    .cms-col.cms-col-11-bd { width: ((100% / 12) * 11); }
    .cms-col.cms-col-12-bd { width: ((100% / 12) * 12); }

    .hidden-on-bd {
        display: none;
    }
}

@include tablet {
    .cms-col.cms-col-1-md { width: ((100% / 12) * 1); }
    .cms-col.cms-col-2-md { width: ((100% / 12) * 2); }
    .cms-col.cms-col-3-md { width: ((100% / 12) * 3); }
    .cms-col.cms-col-4-md { width: ((100% / 12) * 4); }
    .cms-col.cms-col-5-md { width: ((100% / 12) * 5); }
    .cms-col.cms-col-6-md { width: ((100% / 12) * 6); }
    .cms-col.cms-col-7-md { width: ((100% / 12) * 7); }
    .cms-col.cms-col-8-md { width: ((100% / 12) * 8); }
    .cms-col.cms-col-9-md { width: ((100% / 12) * 9); }
    .cms-col.cms-col-10-md { width: ((100% / 12) * 10); }
    .cms-col.cms-col-11-md { width: ((100% / 12) * 11); }
    .cms-col.cms-col-12-md { width: ((100% / 12) * 12); }

    .hidden-on-md {
        display: none;
    }
}

@include phone {
    .cms-col.cms-col-1-sm { width: ((100% / 12) * 1); }
    .cms-col.cms-col-2-sm { width: ((100% / 12) * 2); }
    .cms-col.cms-col-3-sm { width: ((100% / 12) * 3); }
    .cms-col.cms-col-4-sm { width: ((100% / 12) * 4); }
    .cms-col.cms-col-5-sm { width: ((100% / 12) * 5); }
    .cms-col.cms-col-6-sm { width: ((100% / 12) * 6); }
    .cms-col.cms-col-7-sm { width: ((100% / 12) * 7); }
    .cms-col.cms-col-8-sm { width: ((100% / 12) * 8); }
    .cms-col.cms-col-9-sm { width: ((100% / 12) * 9); }
    .cms-col.cms-col-10-sm { width: ((100% / 12) * 10); }
    .cms-col.cms-col-11-sm { width: ((100% / 12) * 11); }
    .cms-col.cms-col-12-sm { width: ((100% / 12) * 12); }

    .hidden-on-sm {
        display: none;
    }
}

.cms-col-inner {
    padding: var(--cms-spacing-x2);
    background-color: transparent;

    > *:last-child {
        margin-bottom: 0;
    }

    &.cms-col-inner-no-padding {
        padding: 0;
    }

    &.center-col {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.cms-col {
   @include image-reset;
}
