/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../style/abstract/theme_colors';
@import '../../style/abstract/theme_sizes';

@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme_mixin';

.NewsletterSubscription {
    padding: 0;

    & .FieldForm {
        position: relative;

        &-Fields {
            width: 100%;

            & .Field {
                &_type_text {
                    & input {
                        background-color: transparent;
                        border: 0;
                        border-bottom: 0.1rem solid var(--white);
                        padding: var(--theme-spacing-24) var(--theme-spacing-16);

                        @include font-footer;

                        caret-color: var(--white);

                        &::placeholder {
                            color: var(--white);
                        }
                    }
                }

                &-Message {
                    bottom: 0;
                }
            }
        }

        &-Button {
            position: absolute;
            right: 0;
            bottom: calc(var(--theme-spacing-16) + 6px);
            background-image: url('image/Newsletter-SignUpButton.svg');
            background-repeat: no-repeat;
            background-position: center;
            filter: invert(100%);
            background-color: transparent;
            border: 0;
            width: var(--theme-spacing-64);
            transition: background-image 200ms ease;

            &:not([disabled]):hover, &:not([disabled]):focus {
                background-image: url('image/Newsletter-SignUpButton-Hover.svg');
                background-color: transparent;
                border: 0;
            }

            &::before, &::after {
                content: none;
            }
        }
    }

    &-CMSMenuOverlaySocialMedia {
        padding-top: var(--theme-spacing-64);

        @include before-desktop {
            padding-top: var(--theme-spacing-48);
        }

        & .CMSMenuOverlaySocialMedia {
            @include before-desktop {
                justify-content: center;
            }
        }

        & .CMSMenuOverlaySocialMedia-Link {
            @include desktop {
                padding: var(--theme-spacing-24);
            }
        }

        & .CMSMenuOverlaySocialMedia-Icon {
            @include desktop {
                width: var(--theme-spacing-24);
                height: var(--theme-spacing-24);
            } 
        }
    }
}